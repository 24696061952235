import React from 'react';
import {graphql} from 'gatsby';
import '../templates/tables.css';
import '../styles/docs-template.scss';
import Layout from '../components/layout';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {markdownRemark} = data; // data.markdownRemark holds your post data
  const {frontmatter, html} = markdownRemark;
  return (
    <Layout>
      {/*<div className="md-content mx-auto">*/}
      {/*  <header className="mb-4">*/}
      {/*    <h1 className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{frontmatter.title}</h1>*/}
      {/*  </header>*/}
      {/*  <div className="md-content-body">*/}
      {/*    <div className="blog-post-content pure-table" dangerouslySetInnerHTML={{__html: html}} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="docs-container pure-table pure-table-bordered">
        {/*<Helmet title={`Datasert - ${frontmatter.title}`} />*/}
        <h2>{frontmatter.title}</h2>
        <div className="content" dangerouslySetInnerHTML={{__html: html}} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
